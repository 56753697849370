<template>
  <div>
    <AuthLayout type="admin">
      <div class="mb-2">
        <img
          src="../../../../assets/icons/padlock-icon.svg"
          alt="padlock-icon"
        />
      </div>
      <h1 class="mb-2 fs-32">Forgot Password</h1>

      <ValidationObserver v-slot="{ invalid }">
        <form @submit.prevent="handleSubmit" class="mb-4">
          <Input
            type="email"
            placeholder="Enter your email address"
            v-model="formData.email"
            name="Email address"
            rules="required|email"
            class="mb-4"
          />
          <Button
            :loading="loading"
            :disabled="invalid || loading"
            theme="blue"
          >
            Send your instructions
          </Button>
        </form>
      </ValidationObserver>

      <router-link
        tag="div"
        class="flex flex__center cursor-pointer"
        :to="{ name: 'adminLogin' }"
      >
        <img src="@/assets/icons/back-icon.svg" alt="back-icon" />
        &nbsp;
        <span class="text-blue">Back to login page</span>
      </router-link>
    </AuthLayout>

    <Modal :active="showModal">
      <div class="flex flex__center">
        <div class="mb-3">
          <img src="@/assets/icons/success-icon.svg" alt="success-icon" />
        </div>
      </div>

      <div class="text-center mb-2">
        <h2 class="mb-1">Reset Password</h2>
        <span class="text-center text__light-grey">
          Please check your inbox and use the instructions in the email to reset
          your password. Be patient, this may take few minutes.
        </span>
      </div>
      <p class="text-center">
        Haven't received yet?
        <button @click="showModal = !showModal" class="cursor-pointer">
          <span class="text-green">Resend email</span>
        </button>
      </p>
    </Modal>
  </div>
</template>

<script>
import AuthLayout from "@/layouts/AuthLayout";
import Modal from "@/components/Modal";
import adminService from "../../../../services/api/adminService";

export default {
  name: "AdminForgotPassword",
  components: {
    AuthLayout,
    Modal
  },
  data() {
    return {
      formData: {},
      showModal: false,
      loading: false
    };
  },
  methods: {
    async handleSubmit() {
      let currentURL = window.location.href;
      currentURL = currentURL.replace("forgot-password", "reset-password");

      this.loading = true;
      try {
        const response = await adminService.forgotPassword({
          ...this.formData,
          redirect_url: currentURL
        });

        if (!response.data.error) {
          this.showModal = true;
        } else {
          this.$notify({
            text: response.data.message,
            title: "Error",
            type: "error"
          });
        }
      } catch (error) {
        this.$notify({
          text: error,
          title: "Error",
          type: "error"
        });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped></style>
